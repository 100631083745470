import React, { Component } from 'react';
import '../Css/About.css';



class About extends Component {

  
  render() {
    
      return (
        <div className="crefty">
        
          <div className="aboutmetext"><h2>Peter</h2>Peter Roehl is a fullstack developer with a focus on frontend UI/UX, hoping to break into game devlopment somewhere down the line. Currently he is working on a few projects for fun, such as a webcam for facial recognition and building a retro game console. When not writing code, you can find him out around the Seattle area exploring on his bike, playing computer games, or cheering on his Oregon Ducks. He hates writing about himself, especially in third person and will probably never change this.</div>
        </div>
       
      );
    } 

}
     
  


export default About;
